import React, { useState, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { Logo } from '../'
import { ThemeContext } from '../../context/ThemeContext'
import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, type } from '../../styles/global'

export const headerHeight = 130
export const phoneHeaderHeight = 65

const Header = (props) => {
	const { fixed, includeCTA, logoColour, ctaColour } = props

	return (
		<Wrapper
			fixed={fixed}
		>
			<Container>
				<HomeLink
					onClick={() => {
						navigate('/')
					}}
				>
					<Logo
						colour={logoColour}
					/>
				</HomeLink>

				{includeCTA && (
					<Menu
						ctaColour={ctaColour}
					>
						<ExternalLink
							href="mailto:info@pavilion.partners"
						>
							Contact
						</ExternalLink>
					</Menu>
				)}
			</Container>
		</Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
	z-index: 10;
	position: ${props => props.fixed ? 'fixed' : 'absolute'};
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
`

const Container = styled.div`
	${container}
	${padding}
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${headerHeight}px;
	padding-top: 72px;

	${media.phone`
		align-items: flex-start;
		height: ${phoneHeaderHeight}px;
		padding-top: 32px;
	`}
`

// Home Link (logo)

const HomeLink = styled.div`
    cursor: pointer;
`


// Menu

const ExternalLink = styled.a``
const Menu = styled.div`
	display: flex;
	
    ${ExternalLink} {
		${type.headerLinks}
		color: black;
		${hoverState}

		${props => {
			if (props.ctaColour)
				return css`
					color: ${props.ctaColour};
				`
		}}
    }
`

export default Header

import React, { useState, useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ThemeContext } from '../../context/ThemeContext'
import { media } from '../../styles/utils'

const Logo = (props) => {
    const theme = useContext(ThemeContext)
    // will default to theme transition colour if no colour prop provided
	const colour = props?.colour || theme.colour

    return (
        <Wrapper>
            <motion.svg width="112px" height="23px" viewBox="0 0 112 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <motion.g initial={{ fill: colour }} animate={{ fill: colour }} transition={{ duration: theme.duration }} id="pavilion-logo" stroke="none" stroke-width="1" fillRule="evenodd">
                    <path d="M29.7384565,8.20243866 L29.7384565,22.5659182 L26.2181591,22.5659182 L26.2181591,21.2148401 C25.0740104,22.3310929 23.5484788,22.9772788 21.8176833,22.9772788 C17.6516164,22.9772788 14.5127019,19.6289368 14.5127019,15.3987509 C14.5127019,11.1689814 17.6516164,7.82063941 21.8176833,7.82063941 C23.5484788,7.82063941 25.0740104,8.46682528 26.2181591,9.58307807 L26.2181591,8.20243866 L29.7384565,8.20243866 Z M26.0711851,15.3987509 C26.0711851,13.1079554 24.4578022,11.4038067 22.2573561,11.4038067 C20.0573264,11.4038067 18.4439435,13.1079554 18.4439435,15.3987509 C18.4439435,17.6899628 20.0573264,19.3936952 22.2573561,19.3936952 C24.4578022,19.3936952 26.0711851,17.6899628 26.0711851,15.3987509 L26.0711851,15.3987509 Z" id="Fill-1"></path>
                    <path d="M49.4835569,3.76694721 C49.4835569,2.47457546 50.5394379,1.38788401 51.8597056,1.38788401 C53.1508283,1.38788401 54.2067093,2.47457546 54.2067093,3.76694721 C54.2067093,5.05931896 53.1508283,6.14601041 51.8597056,6.14601041 C50.5394379,6.14601041 49.4835569,5.05931896 49.4835569,3.76694721" id="Fill-4"></path>
                    <polygon id="Fill-7" points="49.9236877 22.5662513 53.7371004 22.5662513 53.7371004 8.20235539 49.9236877 8.20235539"></polygon>
                    <polygon id="Fill-8" points="57.8644461 22.5662513 61.6778587 22.5662513 61.6778587 0.416481784 57.8644461 0.416481784"></polygon>
                    <path d="M65.3648238,3.76694721 C65.3648238,2.47457546 66.4207048,1.38788401 67.7409725,1.38788401 C69.0320952,1.38788401 70.0879762,2.47457546 70.0879762,3.76694721 C70.0879762,5.05931896 69.0320952,6.14601041 67.7409725,6.14601041 C66.4207048,6.14601041 65.3648238,5.05931896 65.3648238,3.76694721" id="Fill-9"></path>
                    <polygon id="Fill-11" points="65.8047881 22.5662513 69.6186171 22.5662513 69.6186171 8.20235539 65.8047881 8.20235539"></polygon>
                    <path d="M72.7773086,15.3988342 C72.7773086,11.1690647 76.1219033,7.82030632 80.5223792,7.82030632 C84.922855,7.82030632 88.2674498,11.1690647 88.2674498,15.3988342 C88.2674498,19.6286037 84.922855,22.9773621 80.5223792,22.9773621 C76.1219033,22.9773621 72.7773086,19.6286037 72.7773086,15.3988342 M84.3362082,15.3988342 C84.3362082,13.1076223 82.6637026,11.40389 80.5223792,11.40389 C78.3514944,11.40389 76.7085502,13.1076223 76.7085502,15.3988342 C76.7085502,17.6900461 78.3514944,19.3937784 80.5223792,19.3937784 C82.6637026,19.3937784 84.3362082,17.6900461 84.3362082,15.3988342" id="Fill-12"></path>
                    <path d="M94.9764996,8.20243866 L94.9764996,9.34783643 C95.9153844,8.40811896 97.264797,7.82063941 98.7607673,7.82063941 C100.345005,7.82063941 101.753541,8.43726394 102.780277,9.49481041 C103.865719,10.5523569 104.511072,12.0208476 104.511072,14.2829145 L104.511072,22.5659182 L100.697243,22.5659182 L100.697243,14.6055911 C100.697243,13.254513 100.403712,12.5791822 99.904916,12.0795539 C99.4356818,11.580342 98.7316223,11.2863941 97.9688565,11.2863941 C97.2060907,11.2863941 96.5020312,11.580342 96.0323807,12.0795539 C95.5340015,12.6083271 95.2404699,13.254513 95.2404699,14.6055911 L95.2404699,22.5659182 L91.4266409,22.5659182 L91.4266409,8.20243866 L94.9764996,8.20243866 Z" id="Fill-15"></path>
                    <path d="M107.130165,20.5392178 C107.130165,19.1881398 108.215607,18.1014483 109.56502,18.1014483 C110.914433,18.1014483 111.999875,19.1881398 111.999875,20.5392178 C111.999875,21.8902959 110.914433,22.9769874 109.56502,22.9769874 C108.215607,22.9769874 107.130165,21.8902959 107.130165,20.5392178" id="Fill-17"></path>
                    <path d="M3.96046989,22.5660015 L8.32713755e-05,22.5660015 L8.32713755e-05,0.418313755 L6.92368178,0.418313755 C9.47594944,0.418313755 11.1771836,1.18191227 12.380455,2.38643271 C13.612455,3.62009814 14.3456595,5.35297546 14.3456595,7.2623881 C14.3456595,9.17180074 13.612455,10.9046781 12.380455,12.1383435 C11.1771836,13.3428639 9.47594944,14.1064625 6.92368178,14.1064625 L3.96046989,14.1064625 L3.96046989,22.5660015 Z M9.3585368,4.97117621 C8.80103494,4.38369665 7.97997918,4.03104238 6.45403123,4.03104238 L3.96046989,4.03104238 L3.96046989,10.4933175 L6.45403123,10.4933175 C7.97997918,10.4933175 8.80103494,10.1410796 9.3585368,9.5536 C9.91603866,8.96612045 10.2678602,8.20252193 10.2678602,7.2623881 C10.2678602,6.32267063 9.91603866,5.55865576 9.3585368,4.97117621 L9.3585368,4.97117621 Z" id="Fill-19"></path>
                    <polygon id="Fill-22" points="43.327887 8.18919851 39.7813591 20.3634736 36.2344149 8.18919851 32.3431435 8.18919851 36.5720803 22.5676669 42.9902216 22.5676669 47.2191584 8.18919851"></polygon>
                </motion.g>
            </motion.svg>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    svg {
		width: 269px;
		height: auto;

        ${media.phone`
            width: 162px;
        `}
    }
`

export default Logo
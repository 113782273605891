import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition';

import { MetaTags, Header } from '../'
import { media } from '../../styles/utils';

const Layout = ({ children, meta }) => {
	return (
		<>
			<MetaTags
				{...meta}
			/>

            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/20435860.js"></script>	
            </Helmet>

			<Wrapper>
				<Transition>
					{children}
				</Transition>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
`

export default Layout
